




































































































































import Vue, { VueConstructor } from 'vue';
import { mapGetters } from 'vuex';
import { ApiResource } from '@/types';
import StatusTag from '@/components/media/CommissionStatusTag.vue';

interface VuexBindings {
  detect: (idOrVanity: string) => ApiResource.Business,
  find: (b: string, c: string) => ApiResource.Commission | undefined,
  findCustomer: (u: string) => ApiResource.User | undefined,
  getStats: (c: string) => ApiResource.CommissionStats | undefined,
}

export default (Vue as VueConstructor<Vue & VuexBindings>).extend({
  components: {
    StatusTag,
  },
  computed: {
    ...mapGetters({
      detect: 'business/Detect',
      find: 'business/commission/Find',
      findCustomer: 'business/commission/FindCustomer',
      getStats: 'business/commission/Stats',
    }),
    COMMISSION_ID(): string {
      return this.$route.params.COMMISSION_ID;
    },
    VANITY(): string {
      return this.$route.params.VANITY;
    },
    business(): ApiResource.Business {
      // Fallback to finding by ID automatically
      return this.detect(this.VANITY);
    },
    commission(): ApiResource.Commission | undefined {
      return this.find(this.business.id, this.COMMISSION_ID);
    },
    customer(): ApiResource.User | undefined {
      if (!this.commission) return undefined;
      return this.findCustomer(this.commission.customer_id);
    },
    stats(): ApiResource.CommissionStats | undefined {
      return this.getStats(this.COMMISSION_ID);
    },
  },
});
